@import url('https://fonts.googleapis.com/css?family=Lora:400,700|Raleway:400,400i,600,600i&display=swap&subset=latin-ext');
body {
    color: #000;
    font-size: 0.9vw;
    font-weight: 300;
    font-family: 'Raleway', sans-serif;
}
::-moz-selection {
    background: #a38f62;
    color: #fff;
}
::selection {
    background: #a38f62;
    color: #fff;
}
*:focus {
    outline: none !important;
}
.table-borderless {
    border: none !important;
}
strong {
    font-weight: 600;
}
.vh-75 {
    height: 75vh;
}
.mvh-75 {
    min-height: 75vh;
}
.raleway, .raleway p {
    font-family: 'Raleway', sans-serif !important;
}
a {
    color: #000;
    text-decoration: none;
}
a:hover, a:active, a:focus {
    color: #000;
    text-decoration: underline;
}
.super-row > .col-xl-6:nth-child(even) .col-xl-11 {
    margin-left: auto;
    text-align: right;
}

.super-row > .col-xl-6 .col-xl-11 {
    margin-bottom: 3rem;
    padding: 0;
    color: #fff;
}
.super-row > .col-xl-6 .col-xl-11 > div {
    padding: 2.5rem;
}
.super-row > .col-xl-6:nth-child(even) .col-xl-11 > div {
    background: linear-gradient(270deg, #000, transparent);
}
.super-row > .col-xl-6:nth-child(odd) .col-xl-11 > div {
    background: linear-gradient(90deg, #000, transparent);
}
.super-row > .col-xl-6:nth-child(even) .col-xl-11 > div .sousligne_blanc:after {
    left: unset;
    right: 0;
}
.new-index {
    width: 100%;
    height: 50vh;
}
.new .item {
    height: 520px;
}
.new .item svg {
    width: 130px;
    height: auto;
    margin: auto;
}
.new .owl-prev,
.new .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.5rem !important;
    color: #fff !important;
}
.new .owl-prev {
    left: .25em;
}
.new .owl-next {
    right: .25em;
}
.new .owl-prev svg {
    transform: rotate(90deg);
}
.new .owl-next svg {
    transform: rotate(-90deg);
}
.new svg {
    min-width: 25px;
}
.verify {
    transition: all 700ms ease;
    background-color: #ccc;
    z-index: 77777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 300px;
}
.verify a {
    color: #a38f62
}
.verify img.triman {
    max-width: 100px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify label {
    font-weight: bold;
    color: #a38f62;
}
.verify h1 {
    color: #a38f62;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #a38f62;
    border-color: #a38f62;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
    display: inline-block !important;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #a38f62;
}
ol {
    padding-inline-start: 0;
}
.logo-nav {
    cursor: pointer;
}
.dore {
    color: #a38f62;
}
.txt-gris {
    color: #a6a6a6 !important;
}
.dore a, .dore a:hover, .dore a:focus, .dore a:active {
    color: #a38f62;
    text-decoration: none;
}
.home .ligne_2 {
    padding-top: 10vh;
}
.success {
    color: green;
}
.warning {
    color: orangered;
}
.triman {
    width: 55px;
}
.home .parallax1 {
    background: url(../images/home/chateau_clos.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.home .parallax2 {
    background: url(../images/home/chateau_entree.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-champagne .parallax1 {
    background: url(../images/expertise/art-du-champagne1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-champagne .parallax2 {
    background: url(../images/expertise/art-du-champagne2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-porto .parallax1 {
    background: url(../images/expertise/art-du-porto1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-rose .parallax1 {
    background: url(../images/expertise/art-du-rose1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-rose .parallax2 {
    background: url(../images/expertise/art-du-rose2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-rose .parallax3 {
    background: url(../images/expertise/art-du-rose3.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-rose .parallax4 {
    background: url(../images/expertise/art-du-rose4.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-porto .parallax1 {
    background: url(../images/expertise/art-du-porto1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-porto .parallax2 {
    background: url(../images/expertise/art-du-porto2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-sparkling .parallax1 {
    background: url(../images/expertise/art-du-sparkling1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-sparkling .parallax2 {
    background: url(../images/expertise/art-du-sparkling2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-sparkling .parallax3 {
    background: url(../images/expertise/art-du-sparkling3.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.art-du-sparkling .parallax4 {
    background: url(../images/expertise/art-du-sparkling4.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.pageunite .parallax1 {
    background: url(../images/unite/unite-champagne1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.pageunite .parallax2 {
    background: url(../images/unite/unite-camargue1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.pageunite .parallax3 {
    background: url(../images/unite/unite-portugal1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.demain .parallax1 {
    background: url(../images/engagement/notre-engagement1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.demain .parallax2 {
    background: url(../images/engagement/notre-engagement2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.demain .parallax3 {
    background: url(../images/engagement/engagement-cuves.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.demain .parallax4 {
    background: url(../images/engagement/engagement-parc.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.patrimoine .parallax1 {
    background: url(../images/engagement/notre-engagement_patrimoine1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.patrimoine .parallax2 {
    background: url(../images/engagement/notre-engagement_patrimoine2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.patrimoine .parallax3 {
    background: url(../images/engagement/notre-engagement_patrimoine3.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.patrimoine .parallax4 {
    background: url(../images/engagement/notre-engagement_patrimoine4.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.patrimoine .parallax5 {
    background: url(../images/engagement/notre-engagement_patrimoine5.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.patrimoine .parallax6 {
    background: url(../images/engagement/notre-engagement_patrimoine6.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.philantropique .parallax1 {
    background: url(../images/engagement/notre-engagement_philanthropique1.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.philantropique .parallax2 {
    background: url(../images/engagement/notre-engagement_philanthropique2.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 800px;
}
.vignobles .parallax1 {
    background: url(../images/home/chateau_clos.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 650px;
}
.vignobles .parallax2 {
    background: url(../images/vignobles/vignobles-camargue.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 650px;
}
.vignobles .parallax3 {
    background: url(../images/vignobles/vignobles-provence.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 650px;
}
.vignobles .parallax4 {
    background: url(../images/vignobles/vignobles-douro.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 650px;
}
.vignobles .parallax5 {
    background: url(../images/vignobles/vignobles-hampshire.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 650px;
}
.vignobles .parallax6 {
    background: url(../images/vignobles/vignobles-napa.jpg) 50% 100% no-repeat;
    background-size: cover;
    min-height: 650px;
}
.toptop {
    max-width: 60px;
    left: unset
}
.blanc {
    color: #fff !important;
}
.noir {
    color: #000 !important;
}
.sousligne {
    position: relative;
    text-decoration: none !important;
    color: #000 !important;
}
.sousligne.blanc {
    color: #fff !important;
}
.sousligne:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #a38f62;
    width: 50px;
    height: 2px;
}
@media screen and (min--moz-device-pixel-ratio:0) {
    .sousligne {
        display: inline-block;
    }
    .ico_bas {
        display: none;
    }
}
.sousligne img {
    transition: all 300ms ease;
}
.sousligne[aria-expanded='true'] img {
    transform: rotate(180deg);
    transition: all 300ms ease;
}
.ico_bas {
    max-width: 15px;
    margin-left: .5em;
}
.plus {
    max-width: 25px;
    transition: all ease 300ms;
}
.experiences .col-lg-3 {
    padding-right: 30px;
    padding-left: 30px;
}
.in-experience {
    background: 50% 50% no-repeat;
    background-color: #333;
    transition: all ease 500ms;
    background-size: cover;
    position: relative;
    min-height: 10vh;
}
.in-experience:before {
    content: '';
    background-color: rgba(0,0,0,.4);
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
}
.in-experience:hover {
    box-shadow: 0 0 5vh rgba(125,125,125,.5);
    cursor: pointer;
}
.in-experience:hover:before {
    opacity: 1;
}
.in-experience div {
    transition: all 500ms ease;
    position: absolute;
    text-align: center;
    color: #fff;
    bottom: -7vh;
    opacity: 0;
}
.col_nb_2, .col_nb_4 {
    margin-top: -10vh
}
.in-experience:hover div {
    opacity: 1;
    bottom: 0;
}
.hauteur-simple {
    height: 20vh;
}
.hauteur-double {
    height: 30vh;
}
.hauteur-triple {
    height: 50vh;
}
.oeuvres .col-lg-6 {
    padding-right: 30px;
    padding-left: 30px;
}
.oeuvre, .unite {
    min-height: 40vh;
    background: 50% 50% no-repeat;
    transition: all ease 500ms;
    margin-bottom: 8rem;
    position: relative;
    background-size: cover;
}
.oeuvre.hauteur-double {
    height: 80vh;
}
.oeuvre:hover, .unite:hover {
    box-shadow: 0 0 5vh rgba(125,125,125,.5);
    cursor: pointer;
}
.oeuvre div, .unite div {
    transition: all 500ms ease;
    position: absolute;
    text-align: center;
    color: #fff;
    bottom: -7vh;
    opacity: 0;
}
.oeuvre:hover div, .unite:hover div {
    opacity: 1;
    bottom: 0;
}
.blanc a, .blanc a:hover, .blanc a:focus, .blanc a:active {
    color: #fff;
    text-decoration: none;
}
.acc_gauche h3, .acc_droite h3 {
    margin-top: 70vh;
}
.acc_gauche {
    background: url(../images/engagement/acc_gauche.jpg) 50% 50% no-repeat;
    background-size: cover;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    transition: all 300ms ease;
}
.acc_droite {
    background: url(../images/engagement/acc_droite.jpg) 50% 50% no-repeat;
    background-size: cover;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    transition: all 300ms ease;
}
.acc_droite .btn, .acc_gauche .btn {
    opacity: 0;
    color: #fff !important;
    transition: all 300ms ease;
}
.acc_droite:hover .btn, .acc_gauche:hover .btn {
    opacity: 1;
}
.acc_droite:hover, .acc_gauche:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0);
}
.unite {
    min-height: 70vh;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    transition: all 300ms ease;
}
.unite:hover {
    filter: grayscale(0);
    -webkit-filter: grayscale(0);
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    -o-filter: grayscale(0);
}
.unitechanmpagne {
    background: url(../images/centres/bg_centrechampagne.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.unitecamargue {
    background: url(../images/centres/bg_centrecamargue.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.uniteportugal {
    background: url(../images/centres/bg_centredouro.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.bg_blanc {
    background-color: #fff;
}
.bg_gris {
    background-color: #ccc;
}
.bg_or {
    background-color: #ddcfaf;
}
/* id pour le body recolorisé */
#bg_or {
    background-color: #ddcfaf;
}
.wraper {
    transition: background-color .6s ease;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
#bg_blanc .wraper {
    background-color: #fff;
}
#bg_gris .wraper {
    background-color: #d7d7d7;
}
#bg_vert .wraper {
    background-color: #cdd5b6;
}
#bg_kaki .wraper {
    background-color: #c2c3b8;
}
#bg_vert_pale .wraper {
    background-color: #d9dfd5;
}
#bg_beige .wraper {
    background-color: #d5ccb8;
}
#bg_bleu .wraper {
    background-color: #bccbd7;
}
#bg_rose .wraper {
    background-color: #f5eaea;
}
.sousligne_blanc {
    position: relative;
}
.sousligne_blanc:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    background-color: #fff;
    width: 25%;
    max-width: 100px;
    height: 2px;
}
.smallcaps {
    font-size: .8vw;
    text-transform: uppercase;
    margin-bottom: 2.5em;
}
area {
    border: 1px solid red;
}
.carte {
    width: 100% !important;
}
.zone {
    opacity: 0;
    transition: opacity 300ms ease;
}
a:hover .zone, a.hover .zone {
    opacity: 1
}
.point {
    fill: #A38F62;
    stroke: #FFFFFF;
    stroke-width: 2;
    stroke-miterlimit: 10;
}
.zone-in {
    fill: #205A87;
}
.fill-gris {
    fill: #ccc;
}
.line {
    fill: none;
    stroke: #ccc;
    stroke-width: 2;
    stroke-miterlimit: 10;
}
.millesimes {
    background: url(../images/millesimes/bg_millesimes.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.millesimes2 {
    background: url(../images/millesimes/bg_millesimes2.jpg) 50% 50% no-repeat;
    background-size: cover;
}
h1, h2, h3, h4, h5 {
    font-family: 'Lora', serif;
    font-weight: 400;
}
h1 strong, h2 strong, h3 strong, h4 strong, h5 strong {
    font-weight: 700;
    font-size: 1.1em;
}
h1, h2 {
    font-size: 2.2vw;
    letter-spacing: 1px;
    line-height: 1.1;
    margin-top: .5em;
}
.home h3 {
    font-size: 2.2vw;
}
h1 span, h2 span, h3 span {
    font-size: 1.3em;
}
h3 {font-family: 'Lora', serif;
    font-size: 2.5vw;
    line-height: 1;
    margin-bottom: 1em;
    font-weight: 400;
}
h3 span {
    font-size: 1.5em;
}
h4 {
    font-family: 'Lora', serif;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 2.0vw;
    margin-bottom: 1em;
}
h4 span {
    font-size: 1.8em;
}
.in-experience h4 span {
    font-size: 1.2em;
}
h5 {
    font-family: 'Lora', serif;
    font-weight: 400;
    letter-spacing: 0px;
    font-size: 1.4vw;
    margin-bottom: 1em;
}
.vignoble {
    cursor: pointer;
}
.vignoble a:hover {
    color: #a38f62;
    text-decoration: none;
}
/* styles de base si JS est activé */
.js .input-file-container {
    position: relative;
}
.js .input-file-trigger {
    display: block;
    text-transform: uppercase;
    transition: all 200ms ease;
    padding: .5vh 2vw;
    background: #fff;
    text-align: center;
    border-radius: 30px;
    color: #A38F62;
    cursor: pointer;
}
.js .input-file {
    position: absolute;
    border-radius: 30px;
    text-align: center;
    text-transform: uppercase;
    top: 0; 
    left: 0;
    width: 100%;
    padding: .5vh 2vw;
    opacity: 0;
    cursor: pointer;
}
/* quelques styles d'interactions */
.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
    background: #A38F62;
    color: #333;
}
/* styles du retour visuel */
.file-return {
    margin: 0;
}
.file-return:not(:empty) {
    margin: 1em 0;
}
.js .file-return {
    font-style: italic;
    font-size: .9em;
    font-weight: bold;
}
/* on complète l'information d'un contenu textuel
    uniquement lorsque le paragraphe n'est pas vide */
.js .file-return:not(:empty):before {
    content: "Selected file: ";
    font-style: normal;
    font-weight: normal;
}
.btn {
    font-size: .9vw;
}
.btn-outline-primary {
    border-color: #a38f62;
    background-image: none;
    background-color: transparent;
    color: #fff;
    border-radius: 30px;
    text-transform: uppercase;
    padding: 1vh 4vw;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active {
    border-color: #a38f62 !important;
    box-shadow: none !important;
    background-color: #a38f62 !important;
    color: #fff !important;
}
.btn-outline-secondary {
    border-color: #fff;
    color: #000;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active {
    border-color: #fff !important;
    box-shadow: none !important;
    background-color: #fff !important;
    color: #000 !important;
}
.btn-dore {
    border-color: #a38f62 !important;
    color: #fff !important;
    width: 100%;
    padding: 0.25rem .75rem;
    background-color: #a38f62 !important;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
    font-weight: 500;
    text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-item a.niveau1 {
    padding: 1.4em 1em 0 1em;
    letter-spacing: 1px;
}
.navbar-nav>.nav-item a.niveau1 {
    display: inline-block;
    width: 100%;
    height: 100%;
    max-height: 80px;
    position: relative;
}
.nav-item ul {
    background-color: #f5f5f5;
    transition: all ease 500ms;
    position: fixed;
    opacity: 0;
    display: none;
    z-index: -7;
    right: 0;
    left: 0;
    top: 80px;
    text-align: left;
    min-height: 100px;
    line-height: 1.2;
}
.nav-item ul a {
    color: #9f9f9f;
    text-decoration: none !important;
}
.navbar-nav>.nav-item ul a:hover {
    background-color: transparent !important;
}
.bloc-right {
    margin: 2em 0 2em 1em;
    float: left;
    display: block;
    width: 50%;
}
.nav-item img {
    margin: 2em .5em 2em 0;
    float: left;
}
.nav-item:hover ul {
    opacity: 1;
    display: block;
    z-index: 7777;
}
@media (max-width:1500px) {
    .navbar-light .navbar-nav .nav-link {
        padding: .6em .5em;
        font-size: 16px;
        letter-spacing: initial;
    }
}
nav {
    max-height: 80px;
}
.fixed-top {
    padding-top: 0;
}
header {
    position: relative;
}
header:before {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 7;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0.65+0,0+22,0+100 */
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.35) 0%, rgba(0,0,0,0) 22%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 22%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.35) 0%,rgba(0,0,0,0) 22%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
@media (max-width:770px) {
    nav:after {
        top: 44px;
    }
    .navbar-toggler {
        margin-top: 5px;
    }
}
.navbar-brand {
    margin: 0;
    padding: 0 1em;
    margin-top: .5em;
    transition: all 400ms ease;
}
.navbar-brand img {
    width: 322px;
    height: 89px;
    margin: 0;
    float: inherit;
}
.btn-burger, .close_menu {
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
}/*
.burger.clair path {
    fill: #a38f62;
    transition: all 400ms ease;
}
.burger.fonce path {
    fill: #fff;
}*/
.burger path {
    fill: #fff;
}
.menu {
    transform: translate(0%, 100%) matrix(1, 0, 0, 1, 0, 0);
    background-position: 50% 50%;
    transition: all 400ms ease;
    background-size: cover;
    background-color: #fff;
    overflow-y: scroll;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -7;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
}
.menu.on {
    transform: matrix(1, 0, 0, 1, 0, 0);
    z-index: 77777;
    opacity: 1;
}
.menu h2 {
    font-size: 2vw;
}
.menu h3 {
    font-size: 2vw;
}
.menu.bgunivers {
    background: #fff url(../images/bgunivers.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.menu.bgexpertise {
    background: #fff url(../images/bgexpertise.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.menu.bgcollections {
    background: #fff url(../images/bgcollections.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.menu.bgdivers {
    background: #fff url(../images/bgdivers.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.menu.on {
    opacity: 1;
    z-index: 77777;
}
.menu h2 {
    font-size: 2vw;
}
.ico {
    max-width: 30px;
    max-height: 30px;
}
.close_menu {
    max-width: 25px;
}
.etoile {
    transition: all 500ms ease;
    position: absolute;
    left: 1em;
    top: 1em; 
}
/*.etoile.clair path {
    fill: #a38f62;
    transition: all 400ms ease;
}
.etoile.fonce path {
    fill: #fff;
}*/
nav:not(.nomix) {
    mix-blend-mode: difference;
}
.etoile path {
    fill: #fff;
}
video {
    width: 100%;
    object-fit: cover;
    height: 99vh;
}
.in-experience {
    position: relative;
}
.in-experience a {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
.tirette {
    position: fixed;
    cursor: pointer;
    z-index: 7777;
    top: 40vh;
    left: 0;
}
.bg-light {
    background-color: transparent !important;
}
.btn_close {
    max-width: 20px;
    cursor: pointer;
    position: absolute;
    right: 1em;
    top: 1em;
}
.menu-marque {
    transition: all 300ms ease;
    position: fixed;
    left: -500px;
    z-index: -7;
    opacity: 0;
    top: 25vh;
}
.logo-marque {
    transition: all 300ms ease;
    filter: grayscale(1);
    opacity: .5;
    height: 75px;
    margin: 1em auto;
}
.logo-marque:hover {
    filter: grayscale(0);
    opacity: 1;
}
.menu-marque.on {
    opacity: 1;
    z-index: 7777;
    left: 0px;
    transition: all 300ms ease;
}
.menu-marque .fond {
    background-color: #fff;
}
.fond .row {
    border-bottom: 1px solid #959494;
}
.disabled {
    cursor: not-allowed;
}
.menu-marque h2 {
    font-family: 'Lora', serif;
    font-weight: 400;
    font-size: 15px;
    color: #939393;
    text-transform: uppercase;
}
video::-webkit-media-controls {
    display: none;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #6c757d; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #6c757d; 
}
.disabled {
    display: none;
}
.block_orientation {
    display: none;
}
.customPrevBtn, .customNextBtn {
    background: url(../images/btn-next.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    margin-top: -25px;
    cursor: pointer;
    height: 50px;
    z-index: 77;
    width: 50px;
    right: 20vw;
    top: 55vh;
}
.customPrevBtn {
    right: auto;
    background: url(../images/btn-prev.svg) 50% 50% no-repeat;
    left: 20vw;
}
.bloc_texte {
    position: absolute;
    top: 5vh;
    color: #fff;
    z-index: 777;
    transition: all 700ms ease 500ms;
    opacity: 0;
    margin-top: -10vh
}
.active .bloc_texte {
    opacity: 1;
    margin-top: 0
}
.masque_carousel {
    margin-top: -13vw;
    position: absolute;
    z-index: 77;
}
.customPrevBtn2, .customNextBtn2 {
    background: url(../images/btn-next.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    top: 30vw;
    right: 5vw;
    z-index: 777;
    margin-right: 10px;
    cursor: pointer;
    height: 50px;
    width: 50px;
    margin-top: -25px;
}
.customPrevBtn2 {
    background: url(../images/btn-prev.svg) 50% 50% no-repeat;
    right: auto;
    left: 5vw;
}
.customPrevBtn3, .customNextBtn3 {
    background: url(../images/btn-next-black.svg) 50% 50% no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 777;
    cursor: pointer;
    height: 50px;
    width: 50px;
    margin-top: -25px
}
.customPrevBtn3 {
    background: url(../images/btn-prev-black.svg) 50% 50% no-repeat;
    right: 0;
    left: unset;
}
.owl-carousel1 .item {
    transform: scale(.8);
    transition: .4s ease all;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.owl-carousel1 .active .item {
    transform: scale(1);
}
.owl-carousel2 .item {
    transition: .4s ease all;
    cursor: move;
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}
.timeline {
    height: 300px;
}
.date {
    width: 15px;
    cursor: pointer;
}
.fill-txt-gris {
    fill: #fff;
}
.modal-header {
    text-align: center;
    color: #A38F62;
    border-bottom: 0px;
}
.modal-body img {
    object-fit: cover;
}
.modal-body {
    padding-bottom: 0;
    padding-top: 0;
    margin-left: -1px;
    margin-right: -1px;
}
.modal-dialog {
    max-width: 800px;
}
.modal-title {
    width: 100%;
}
.modal-content {
    border: 1px solid #A38F62;
    border-radius: 0;
}
/*.offres div.mb-4 p {*/
/*    margin-bottom: 0;*/
/*}*/
button.close {
    padding: 0 1rem !important;
    font-size: 3.5rem;
    font-weight: 400;
    color: #856404;
    right: .7em;
    top: 0.2em;
}
.titre {
    transition: all 200ms ease;
    position: absolute;
    max-width: 100%;
    width: 500px;
    top: 125px;
    opacity: 0;
}
.titre h3 {
    font-size: 1.7vw;
    font-weight: normal;
}
.titre h4 {
    font-size: 1.1vw;
}
.titre.on {
    opacity: 1;
}
.bulle {
    width: 206px;
    position: absolute;
}
.engagement {
    min-height: 70vh;
    position: relative;
    transition: all 500ms ease;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    cursor: pointer;
}
.engagement h4 {
    font-size: 1.1vw;
    margin-bottom: .5em
}
.in-experience h4 {
    font-size: 1vw;
}
.oeuvre h4 {
    font-size: 1.5vw;
}
.engagement div {
    opacity: 0;
    transition: all 500ms ease;
    position: absolute;
    bottom: -15vh;
    color: #fff;
}
.engagement:hover div {
    opacity: 1;
    bottom: 0;
}
.engagement:hover {
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}
.engagement1 {
    background: url(../images/univers/engagement1.jpg) 50% 100% no-repeat;
    background-size: cover;
}
.engagement2 {
    background: url(../images/univers/engagement2.jpg) 50% 100% no-repeat;
    background-size: cover;
}
.engagement3 {
    background: url(../images/univers/engagement3.jpg) 50% 100% no-repeat;
    background-size: cover;
}
.imgvisite {
    transition: all 300ms ease;
    position: absolute;
    z-index: -7;
    opacity: 0;
}
.imgvisite.on {
    z-index: 7;
    opacity: 1;
}
.row_visite {
    min-height: 800px
}
.list-none {
    list-style: none;
}
.abus {
    background-color: #efefef;
    font-weight: 300;
    color: #000;
}
footer {
    background-color: #09283f;
    font-weight: 300;
    color: #fff;
    min-height: 80px;
    margin-top: auto;
}
footer ul {
    display: inline;
    list-style-type: none;
    padding-inline-start: 0;
}
footer a {
    color: #fff !important;
}
.socials img {
    width: 40px;
    margin-right: 1em;
    max-height: 35px;
}
.socials img.col-8 {
    width: auto;
    max-height: inherit;
    margin-right: 0;
}
.socials div {
    min-width: 500px;
 }
.socials img:last-child {
    margin-right: 0;
}
body:not(.home) .navbar-collapse, body:not(.home) .navbar-brand {
    display: none !important;
}
body:not(.home) .etoile {
    opacity: 1 !important;
}
.form-control:focus {
    border-color: #a38f62;
    box-shadow: 0 0 0 0.2rem rgba(163, 143, 98, .25);
}
.form-control:focus {
    border-radius: 0;
}
textarea {
    min-height: 200px;
}

.h3_blanc h3{
    color: #a38f62;
    font-size: 2.2vw;
}
.h3_vert h3{
    color: #fff;
    font-size: 2.2vw;
}
@media (max-width:820px) and (orientation: landscape) {
    .block_orientation {
        background-color: #333;
        color: #000;
        z-index: 777777777;
        display: block;
        padding: 1em 0;
        font-size: 3.5vw;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        text-align: center;
        top: 0;
    }
}
@media (min-width: 2000px) {
    .parallax1, .parallax2, .parallax3, .parallax4 {
        min-height: 65vh !important;
        background-size: 100%
    }
}
@media (max-width: 1500px) {
    body, .btn {
        font-size: 1.3vw
    }
    .ico_bas {
        display: none;
    }
    .menu-marque {
        top: 15vh;
    }
}
@media (max-width: 1024px) {
    .parallax1, .parallax2, .parallax3, .parallax4 {
        min-height: 65vh !important;
        background-position: 50% 100%;
    }
    .smallcaps {
        font-size: 1.2vw;
    }
    .engagement h4 {
        font-size: 1.7vw;
    }
    .experiences .col-lg-3 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .in-experience {
        margin-bottom: 1rem !important;
    }
    .les-oeuvre .oeuvres .col-lg-4 {
        margin-left: 5%;
    }
    .col_nb_2, .col_nb_4 {
        margin-top: -5vh
    }
    h3 {
        font-size: 3.8vw;
    }
    h4 {
        font-size: 3.4vw;
    }
    h4 span {
        font-size: 1.2em;
    }
    .logo-marque {
        height: 55px;
    }
    .menu-marque .fond {
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        border: 1px solid #959494
    }
    .btn_close {
        right: .5em;
        top: .5em;
    }
}
@media (max-width: 980px) {
    .home .ligne_2 {
        padding-top: 5vh;
    }
    h1, h2 {
        font-size: 4.5vw;
    }
    body, .btn {
        font-size: 2.2vw
    }
    .menu h2 {
        font-size: 2.1vw;
    }
    .menu h3 {
        font-size: 2.1vw;
    }
    .tirette {
        /*display: none;*/
        margin-left: -6px;
        transform: scale(.7);
    }
    body:not(.home) .etoile {
        opacity: 0 !important;
    }
    .engagement h4 {
        font-size: 5.1vw;
    }
    .in-experience h4 {
        font-size: 4.1vw;
    }
    .smallcaps {
        font-size: 1em;
    }
    .col_nb_2, .col_nb_4 {
        margin-top: 0;
    }
    .hauteur-simple {
        height: 30vh;
    }
    .hauteur-double {
        height: 40vh;
    }
    .hauteur-triple {
        height: 60vh;
    }
    .block_orientation {
        font-size: 2.6vw;
        color: #fff;
    }
}
@media (max-width: 600px) {
    body {
        font-size: 17px;
    }
    .btn {
        font-size: 14px;
    }
    .owl-carousel2 .owl-item {
        font-size: .8em !important;
    }
    .owl-carousel2 .owl-item img {
        width: auto !important;
        max-width: 600px !important;
        height: auto !important;
        max-height: 450px !important;
    }
    .owl-carousel2 .smallcaps {
        display: none;
    }
    .owl-carousel2 .mt-4 {
        margin-top: .5em !important;
    }
    .owl-carousel2 h3 {
        font-size: 1.5em;
    }
    .bloc_texte {
        min-height: 500px;
    }
    .customPrevBtn2, .customNextBtn2 {
        top: 6vh;
        height: 30px;
        width: 30px;
    }
    .customPrevBtn, .customNextBtn {
        top: 50vh
    }
    .customPrevBtn3 {
        left: -5px
    }
    .customNextBtn3 {
        right: 0px;
        left: initial;
    }
    .menu h2 {
        font-size: 2em;
    }
    .menu h3 {
        font-size: 2em;
    }
    h1, h2 {
        font-size: 1.6em;
        margin-bottom: .5em;
    }
    h3 {
        font-size: 2em;
    }
    .h3_blanc h3{
        color: #a38f62;
        font-size: 2em;
    }
    .h3_vert h3{
        color: #fff;
        font-size: 2em;
    }
    h4 {
        font-size: 1.8em;
    }
    .home h3 {
        font-size: 1.2em;
    }
    .etoile, .navbar-brand {
        display: none;
    }
    .les-oeuvre .oeuvres .col-lg-4 {
        margin-left: 0;
    }
    .oeuvre, .unite {
        margin-bottom: 2vh  ;
    }
}